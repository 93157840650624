<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <div class="form-group">
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tableVehicleCategories.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
        >
          <b-button
            type="button"
            variant="success"
            autocomplete="off"
            @click="onCreate"
          >
            New Vehicle Category
          </b-button>
        </b-col>

      </b-row>
      <b-table
        ref="tableVehicleCategories"
        hover
        responsive
        class="mt-2"
        :per-page="tableVehicleCategories.perPage"
        :current-page="tableVehicleCategories.currentPage"
        :items="tableProvider"
        :fields="tableVehicleCategories.fields"
        :sort-by.sync="tableVehicleCategories.sortBy"
        :sort-desc.sync="tableVehicleCategories.sortDesc"
        :sort-direction="tableVehicleCategories.sortDirection"
        :filter="tableVehicleCategories.filter"
        :filter-included-fields="tableVehicleCategories.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableVehicleCategories.currentPage * tableVehicleCategories.perPage - tableVehicleCategories.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <b-button
            size="sm"
            variant="primary"
            @click="onEdit(row.item)"
          >
            Edit
          </b-button>
        </template>

      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableVehicleCategories.perPage"
              :options="tableVehicleCategories.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableVehicleCategories.currentPage"
            :total-rows="tableVehicleCategories.totalRows"
            :per-page="tableVehicleCategories.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-vehicle-category-form"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="modalTitle"
      @ok="onValidate"
    >
      <ValidationObserver
        ref="formVehicleCategory"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <ValidationProvider
            #default="{ errors }"
            name="name"
            vid="name"
            rules="required|max:150"
          >
            <div class="form-group">
              <label for="name">
                <strong>Name</strong>
              </label>
              <b-input
                id="name"
                v-model="vehicleCategory.name"
                type="text"
                placeholder="enter name"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
                v-text="errors[0]"
              />
            </div>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="vehicleCategory.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              switch
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, SLOVehicleCategory } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'LogisticsOfficerVehicleCategories',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Vehicle Categories'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false
      },
      vehicleCategory: {
        id: 0,
        name: '',
        active: 0
      },
      tableVehicleCategories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: ''
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'name', sortable: true },
          { key: 'active' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Vehicle Category' : 'New Vehicle Category'
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableVehicleCategories.busy = true
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search
      })

      return SLOVehicleCategory.get(filters).then(
        ({ data }) => {
          this.tableVehicleCategories.totalRows = data.total_rows
          return data.items
        }
      )
        .catch(() => {
          this.tableVehicleCategories.totalRows = 0
          return []
        })
        .finally(() => {
          this.tableVehicleCategories.busy = false
        })
    },

    onCreate () {
      this.state.editing = false
      this.vehicleCategory.name = ''
      this.vehicleCategory.active = 1
      this.$bvModal.show('modal-vehicle-category-form')
    },

    onEdit (item) {
      this.state.editing = true
      this.vehicleCategory.id = item.id
      this.vehicleCategory.name = item.name
      this.vehicleCategory.active = item.active
      this.$bvModal.show('modal-vehicle-category-form')
    },

    async onValidate (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formVehicleCategory.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: this.state.editing ? 'Update Vehicle Category?' : 'Create Vehicle Category?',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Confirm',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.state.busy = false
                if (this.state.editing) {
                  return this.onPut()
                }

                return this.onPost()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPost () {
      return new Promise(
        resolve => {
          SLOVehicleCategory.post(this.vehicleCategory).then(
            ({ data }) => {
              this.$bvModal.hide('modal-vehicle-category-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.$refs.tableVehicleCategories.refresh()
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formVehicleCategory.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    },

    async onPut () {
      return new Promise(
        resolve => {
          SLOVehicleCategory.put(this.vehicleCategory).then(
            ({ data }) => {
              this.$bvModal.hide('modal-vehicle-category-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                const row = _.find(this.$refs.tableVehicleCategories.localItems, {
                  id: data.vehicleCategory.id
                })
                row.name = data.vehicleCategory.name
                row.active = data.vehicleCategory.active
                row.updated_at = data.vehicleCategory.updated_at
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formVehicleCategory.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
